.inputField {
    width: 100%;
    height: 5.6rem;
    border: 2px solid #e1dddd94;
    border-radius: 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-indent: 1rem;
}

.inputTextArea {
    width: 100%;
    height: 12rem;
    border: 2px solid #e1dddd94;
    border-radius: 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-indent: 1rem;
}

.inputField::placeholder {
    color: #443d3d59;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.formSelect {
    background-image: url("../../assets//img/selectArrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 90% 55% !important;
    background-size: 14px 10px !important;
}

.inputField:focus {
    outline: none;
}

.mainHeading {
    font-size: 4.5rem;
    font-weight: 600;
}

.subHeading {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
}

.formLabel {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: .7rem;
}

.otpBox input {
    height: 5rem;
    width: 5rem !important;
    font-size: 1.8rem;
    font-weight: 500;
    border-radius: 1rem;
    background-color: transparent;
    color: black;
    border: 2px solid #e1dddd94;
}

.otpBox input:focus {
    outline: none;
}

.otpBox div {
    justify-content: start;
    gap: .7rem;
}