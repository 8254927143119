.contactUstext {
    font-size: 2.4rem;
    font-weight: 600;
    color: #525252;
}

.footertextColor {
    font-size: 1.6rem;
    color: var(--appThemeColor);
    margin-bottom: 0;
}

.callImg {
    width: 2.3rem;
}

.footer {
    padding: 5rem 12rem;
    background-color: #f1f1f1;
}

.footerHeading {
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    gap: 1rem;
}

hr {
    background-color: var(--appThemeColor);
    height: .3rem !important;
    opacity: 1;
}

.footerLinks li {
    font-size: 1.6rem;
    font-weight: 400;
}

.footerLinks a {
    color: black;
    text-decoration: none;
}