html {
  font-size: 62.5%;
}

:root {
  --appThemeColor: #2ECC40;
}

.cursor {
  cursor: pointer;
}

.marginContainer {
  margin: 0 12rem;
}

.Toastify__toast-body>div:last-child {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

.accordion-button:not(.collapsed) {
  background: none !important;
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
  color: var(--appThemeColor);
}

.accordion-item {
  border: 0;
  border-bottom: 1px solid var(--appThemeColor);
}

.accordion-body {
  background-color: var(--appThemeColor);
}

.accordion-body .description {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.customScroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.customScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #CAE9FC;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--appThemeColor);
}

::-ms-reveal {
  display: none;
}

.cartFooterHeading {
  font-size: 1.6rem;
  font-weight: 500;
}

.cartFooterText {
  font-size: 1.8rem;
  font-weight: 400;
  color: #9F9F9F;
}

.cartFooter {
  background-color: #F0F0F0;
}

.whatsappImg {
  height: 8rem;
  width: 8rem;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  cursor: pointer;
  z-index: 9;
}

.whatsappImg {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.whatsappImg:hover {
  transform: scale(1.1);
}

button:disabled {
  cursor: not-allowed;
}

/* .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
} */

/* .slick-prev,
.slick-next {
  height: 4rem !important;
  width: 4rem !important;
  background-color: silver !important;
  border-radius: 50% !important;
} */

/* .slick-prev:hover,
.slick-next:hover {
  background-color: silver !important;
} */

/* .sliderImg img {
  width: 30rem;
  height: 30rem;
  object-fit: contain;
}

.carousel-item {
  cursor: pointer;
} */

.slick-prev,
.slick-next {
  width: unset !important;
  height: unset !important;
}

.slick-prev:before,
.slick-next:before {
  display: none !important;
}

.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image img {
  transition: transform 0.5s ease;
}

.product-image img.zoomed {
  transform: scale(1.2);
}

.uploadImgBtn {
  position: absolute;
  width: 11rem;
  opacity: 0;
}

.showOnMob {
  display: none;
}

/* Media Query for Small Devices */
@media(max-width:768px) {
  html {
    font-size: 50%;
  }
}

@media(max-width:600px) {
  .marginContainer {
    margin: 0 1rem !important;
  }

  .caroIcons{
    display: none;
  }

  .carousel-prev-icon {
    margin-left: -5rem !important;
  }

  .carousel-next-icon {
    margin-right: -5rem !important;
  }

  .paddingTopnav {
    padding: 1rem;
  }

  .textWlcm {
    display: none;
  }

  .showOnMob {
    display: block;
  }

  .hideOnMob {
    display: none;
  }

  .navbarInfoDiv {
    margin-top: 2rem;
    border: 0;
    padding-left: 0;
    margin-bottom: 2rem;
  }

  .footer {
    padding: 1rem;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .cartFooterHeading {
    font-size: 1.3rem;
    margin-bottom: 0;
  }
}

/* Media Query for Small Devices */